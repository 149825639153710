
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Chat } from '../../infrastructure/api-slice'

interface ChatState {
  chats: Chat[];
}

const initialState: ChatState = {
  chats: [],
}

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChats(state: ChatState, action: PayloadAction<Chat[]>) {
      state.chats = action.payload;
    }
  }

});

export const { setChats } = chatsSlice.actions;
export default chatsSlice.reducer;

