/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import {
  useFetchAnswerQuery,
  useFetchChatQuery,
  useDownloadFileMutation,
  Answer,
  Chat,
  useGetProfileQuery
} from "../../infrastructure/api-slice";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import { setChatId } from "../../domain/chats/chat-slice";
import { setChats } from "../../domain/chats/chats-slice";
import { Button } from "@material-tailwind/react";
import { setIsSearching } from "../../domain/search/search-slice";
import { checkLoginAndRedirect } from '../../utils/authUtils';

function Home() {
  const dispatch = useAppDispatch();
  const topChats = useAppSelector(state => state.chats.chats)
  const loaderType = useAppSelector(state => state.loaderType)
  const isSearching = useAppSelector(state => state.search.isSearching)
  const [skip, setSkip] = useState(true)
  const [url, setUrl] = useState("")
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [question, setQuestion] = useState<string>("");
  const [newChatId, setNewChatId] = useState<string>("");
  const chatId = useAppSelector((state) => state.chat.id)
  const { data = {} as Answer, isFetching, error } = useFetchAnswerQuery({ question, chatId, loader_type_name: loaderType.name, loader_type_description: loaderType.description }, { skip });
  const { data: chat = {} as Chat, isFetching: isFetchingChat } = useFetchChatQuery(chatId, { skip: chatId === "" });
  const ref = useRef<HTMLDivElement>(null);
  const [downloadFile] = useDownloadFileMutation();
  const { data: profileData, isFetching: isFetchingProfile, isUninitialized } = useGetProfileQuery();
  const [hasRun, setHasRun] = useState(false);
  useEffect(() => {
        if (isFetchingProfile || !hasRun) {
          setHasRun(true);
          return;
        }
        checkLoginAndRedirect(profileData);
    }, [profileData, isFetchingProfile, isUninitialized, hasRun]);

  useEffect(() => {
    if (url !== "") {
      downloadFile(url);
      setUrl("")
    }
  }, [downloadFile, url]);

  useEffect(() => {
    if (newChatId !== "")
      dispatch(setChatId(newChatId))
  }, [dispatch, newChatId]);

  useEffect(() => {
    if (chatId === "")
      setAnswers([]);
  }, [chatId]);

  useEffect(() => {
    if (isFetchingChat || !chat.title) return;

    document.title = ""

    if (chatId === "")
      return

    dispatch(setChatId(chat.chatId))

    document.title = chat.title

    setAnswers(chat.conversation)

  }, [isFetchingChat, chatId]);

  useEffect(() => {
    if (answers.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [answers.length]);

  useEffect(() => {
    if (isFetching || skip || question === "" || !data.answer) return;
    setSkip(true)
    dispatch(setIsSearching(false))

    if (error) {
      if ('status' in error && error.status === 401) {
        window.location.href = "/login";
      }
      return
    }

    answers[answers.length - 1] = data

    if (chatId !== data.chatId) {
      dispatch(setChats([{ chatId: data.chatId, title: decodeURI(question), loader_type_name: loaderType.name, loader_type_description: loaderType.description } as Chat, ...topChats.filter((chat: Chat) => chat.chatId !== data.chatId)]))
      setNewChatId(data.chatId)
    }

    setQuestion("")
    // }, [answers, chatId, data, dispatch, error, isFetching, loaderType.description, loaderType.name, question, skip, topChats])
  }, [isFetching, skip])

  useEffect(() => {
    const input = document.getElementById("input");
    const search = document.getElementById("search");
    const searchContainer = document.getElementById("searchContainer");
    input?.setAttribute("contenteditable", (!isSearching).toString())
    if (isSearching) {
      search?.setAttribute("disabled", "true")
      searchContainer?.classList.add("disabled");
    } else {
      search?.removeAttribute("disabled")
      searchContainer?.classList.remove("disabled");
    }

  }, [isSearching])

  const ask = () => {
    const input = document.getElementById("input")
    if (!question) return
    if (input) {
      input.innerText = ""
    }
    setAnswers([...answers, { question: decodeURI(question) } as Answer]);
    setSkip(false)
    dispatch(setIsSearching(true))
  }


  const handleChange = (e: any) => {
    setQuestion(encodeURI(e.target.innerText))
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        document.execCommand('insertLineBreak')
      } else {
        ask()
      }
      e.preventDefault()
    }
  }

  const download = (e: any, url: string) => {
    setUrl(url)
    e.preventDefault();

  }
  return (
    <div className="h-screen grid grid-cols-1">
      <div className="flex w-1/2 justify-self-center mt-10 max-h-[90%] overflow-auto">
        <div>
          {answers.map((answer: Answer, index) => {
            return <div key={index}>
              <div className={`pb-5 whitespace-pre-wrap transition-opacity ease-in-out delay-150 duration-1000 ${!answer.question ? "opacity-0" : "opacity-100"}`}><span className="font-black">Du:</span> {answer.question}</div>
              <div className={`pb-5 whitespace-pre-wrap transition-opacity ease-in-out delay-150 duration-1000 ${!answer.answer ? "opacity-0" : "opacity-100"}`}><span className="font-black">Gpt:</span> {answer.answer}</div>
              <div className={`pb-5 whitespace-pre-wrap transition-opacity ease-in-out delay-150 duration-1000 ${!answer.source ? "opacity-0" : "opacity-100"}`}><span className="font-black">Källa: </span> <a href='#' onClick={(e) => { download(e, answer.source) }}>{answer.source}</a></div>
            </div>
          })}
          <div ref={ref}></div>
        </div>

      </div>

      <div id="searchContainer" className="relative flex min-w-[24rem] self-end justify-self-center pb-10">
        <div id="input" className="p-1 mr-2 w-96 text-white border-2 border-white rounded whitespace-pre-wrap" contentEditable="true" onInput={handleChange} onKeyDown={handleKeyDown}></div>
        <Button
          id="search"
          size="sm"
          className="pb-2 rounded h-10 w-22 text-white bg-neutral-700 hover:border-neutral-400 hover:text-neutral-400 border-white border-2 shadow-none hover:shadow-none "
          onClick={ask}>
          Skicka
        </Button>
      </div>
    </div>
  );
}

export default Home;
