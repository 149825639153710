import Layout from "../layouts/Layout";
import Home from "../UI/Organisms/Home";


function Root() {
  return (
    <Layout>
      <Home />
    </Layout>
  );
}

export default Root;
