import Navigation from "../UI/Molecules/Navigation";

type ContainerProps = {
  children: React.ReactNode;
};

const Layout = (props: ContainerProps) => {
  return (
    <div className="flex flex-row">
      <div className="bg-black min-h-screen h-window hidden sm:block basis-72">
        <Navigation />
      </div>
      <div className="grow bg-neutral-700 text-white px-6">
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
