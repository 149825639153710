import React from "react";
import { useGetProfileQuery } from "../../infrastructure/api-slice";
import { useEffect } from "react";

function AdminNavigation() {
  const { data: profileData, isFetching: isFetchingProfile } =
    useGetProfileQuery();

  useEffect(() => {
    if (isFetchingProfile) return;
    if (profileData) {
      if (!profileData.is_superuser) {
        window.location.href = "/";
      }
    }
  }, [profileData, isFetchingProfile]);
  return (
    <div className="font-bold">
      <div className="text-white p-2">
        <a href="/">Hem</a>
      </div>
      <div className="text-white p-2">
        <a href="/admin/loaders">Hantera källor</a>
      </div>
      <div className="text-white p-2">
        <a href="/admin/users">Hantera användare</a>
      </div>
    </div>
  );
}

export default AdminNavigation;
