
import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  useGetCustomerByIdQuery,
  useGetProfileQuery,
  usePutCustomerMutation,
  useDeleteFileMutation,
  useGetFilesQuery,
  useUploadFileMutation,
  Loader,
  Customer,
} from "../infrastructure/api-slice";
import Select from "react-tailwindcss-select";
import { Input } from "@material-tailwind/react"
import { SelectValue } from "react-tailwindcss-select/dist/components/type";
import { Button } from '@material-tailwind/react';

function Loaders() {
  const { data: profileData } = useGetProfileQuery();
  const { data: customer = {} as Customer, isFetching, refetch: refetchCustomer } = useGetCustomerByIdQuery(profileData?.customer_id || "", { skip: !profileData });
  const [loaderTypes, setLoaderTypes] = useState<Loader[]>([]);
  const [loaderType, setLoaderType] = useState<SelectValue>(null);
  const [loader, setLoader] = useState<Loader>({
    folder: '',
    allowed_users: [],
    amount_of_similar_documents: 0,
    chunk_overlap: 0,
    chunk_size: 0,
    prompt: ''
  } as Loader);
  const [updateCustomer] = usePutCustomerMutation();
  const { data: files = [] as string[], isFetching: isFetchingFiles, refetch } = useGetFilesQuery({ customer_name: customer?.name || "", folder: loader.folder || "" }, { skip: !loader.folder });
  const [deleteFile] = useDeleteFileMutation();
  const [uploadFile] = useUploadFileMutation();
  const directory = { directory: "", webkitdirectory: "" };
  const [customerId, setCustomerId] = useState<string>("");
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    console.log('useEffect');
    if (!isFetching) {
      setLoaderTypes(customer.loaders || []);
      setCustomerId(customer.id || "");
    }
  }, [customer.id, customer.loaders, isFetching]);


  const handleLoaderTypeChange = (value: any) => {
    if (!value) return;
    setLoaderType(value);
    setLoader(loaderTypes.find((loader) => loader.folder === value.value) || {} as Loader);
    setCreateMode(false);
  };

  useEffect(() => {

    if (!customer.loaders) {
      return;
    }

    if ((createMode && !loader.folder) || loader.folder === "") {
      return;
    }

    let customer_copy = { ...customer };
    customer_copy.loaders = customer.loaders.map((l) => {
      if (l.folder === loader.folder) {
        return loader;
      }
      return l;
    });
    if (!customer.loaders.find((l) => l.folder === loader.folder)) {
      customer_copy.loaders.push(loader);
    }

    if (customerId && customerId !== "") {
      updateCustomer(customer_copy);
      setCreateMode(false);
      refetchCustomer();
      setLoaderType({ label: loader.folder, value: loader.folder });
    }
  }, [createMode, customer, customerId, loader, refetchCustomer, updateCustomer]);

  const handleDeleteFile = async (file: string) => {
    await deleteFile(file);
    refetch();
  }

  const handleUpload = async (e: any) => {
    const files = e.target.files;
    if (!files) return;
    
    setIsUploading(true);
    await uploadFile({ customer_name: customer.name, folder: loader.folder, files: files });
    setIsUploading(false);
    refetch();
    e.target.value = null;
    setCreateMode(false);
  }

  const newLoader = () => {
    console.log('newLoader');
    setCreateMode(true);
    setLoader({ folder: '', allowed_users: [], amount_of_similar_documents: 0, chunk_overlap: 0, chunk_size: 0, prompt: '' })
    loaderType && setLoaderType(null);
  }

  const verify = (e: any) => {
    console.log('verify');
    if (e.target.value !== "" && window.confirm('Är du säker på att du vill namnge mappen till ' + e.target.value + '? Detta kan inte ångras.')) {
      setLoader({ ...loader, folder: e.target.value });
    }
  }

  const deleteLoader = () => {
    if (window.confirm('Är du säker på att du vill radera källan ' + loader.folder + '? Detta kan inte ångras.')) {
      let customer_copy = { ...customer };
      customer_copy.loaders = customer.loaders.filter((l) => l.folder !== loader.folder);
      updateCustomer(customer_copy);
      setLoader({ folder: '', allowed_users: [], amount_of_similar_documents: 0, chunk_overlap: 0, chunk_size: 0, prompt: '' });
      setCreateMode(false);
      setLoaderType(null);
      refetchCustomer();
    }
  }

  const deleteAllFiles = async () => {
    if (window.confirm('Är du säker på att du vill radera alla filer i källan ' + loader.folder + '? Detta kan inte ångras.')) {
      Array.isArray(files) && files.forEach((file) => {
        handleDeleteFile(file);
      });
    }
  }

  return (
    <Layout>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col w-1/2 mt-24">
          <h1 className="text-4xl font-bold text-center">{customer.name}</h1>
          <h2 className="text-3xl text-center mb-4">Källor</h2>
          {customerId && customerId !== "" && (
            <div>
              <div className='mb-8 flex flex-row'>
                <div className='mr-4 w-8/12'>
                  <label className="text-sm text-gray-500">Välj källa</label>
                  <Select
                    value={loaderType}
                    primaryColor={"blue"}
                    onChange={handleLoaderTypeChange}
                    options={loaderTypes.map((loader) => ({
                      label: loader.folder,
                      value: loader.folder,
                    }))}
                    classNames={{
                      menuButton: () => (
                        `flex text-sm text-white border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none px-4 ${"bg-neutral-700 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                        }`
                      ),
                      menu: "absolute z-10 w-full bg-neutral-700 shadow-lg border rounded py-1 mt-1.5 text-sm text-white",
                      searchBox: "w-full py-2 pl-8 text-sm bg-neutral-700 text-white  border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                      listItem: () => (
                        `block transition px-4 duration-200 px-2 py-2 cursor-pointer select-none truncate rounded hover:bg-neutral-800 ${`text-white bg-neutral-700`
                        }`
                      ),
                      tagItem: () => ("bg-neutral-700 border text-white rounded-sm flex space-x-1 pl-1"),
                      tagItemText: "text-white",
                    }}
                  />
                </div>
                <div className='mt-6 ml-9'>
                  <button className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded" onClick={() => newLoader()}>Skapa ny källa</button>
                </div>
              </div>
              <div className={`justify-center h-screen mt-5 ${(loader && loader.folder) || createMode ? '' : 'hidden'}`}>
                <div className='text-white'>
                  <div className=''>
                    <div className={`mb-4 ${createMode ? '' : 'hidden'}`}>
                      <Input label="Mapp" id='folder' type="text" color='white' onBlur={(e) => verify(e)} crossOrigin={undefined} />
                    </div>
                    <div className={`mb-4 ${!createMode ? '' : 'hidden'}`}>
                      <Input label="Tillåtna användare. (, separerade)" id='allowed' type="text" color="white" value={loader.allowed_users} onChange={(e) => setLoader({ ...loader, allowed_users: e.target.value.split(",") })} crossOrigin={undefined} />
                    </div>
                    <div className={`mb-4 ${!createMode ? '' : 'hidden'}`}>
                      <Input label="Antal likadana dokument" id='same' color='white' type="number" value={loader.amount_of_similar_documents} onChange={(e) => setLoader({ ...loader, amount_of_similar_documents: Number(e.target.value) })} crossOrigin={undefined} />
                    </div>
                    <div className={`mb-4 ${!createMode ? '' : 'hidden'}`}>
                      <Input label="Styckes överlappning" id='overlap' type="number" color='white' value={loader.chunk_overlap} onChange={(e) => setLoader({ ...loader, chunk_overlap: Number(e.target.value) })} crossOrigin={undefined} />
                    </div>
                    <div className={`mb-4 ${!createMode ? '' : 'hidden'}`}>
                      <Input label="Styckes storlek" type="number" id='size' color='white' value={loader.chunk_size} onChange={(e) => setLoader({ ...loader, chunk_size: Number(e.target.value) })} crossOrigin={undefined} />
                    </div>
                    <div className={`mb-4 ${!createMode ? '' : 'hidden'}`}>
                      <div className="relative w-full min-w-[200px]">
                        <textarea
                          className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-white border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-white placeholder-shown:border-t-white focus:border-2 focus:white focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0"
                          title="Prompt"
                          onChange={(e) => setLoader({ ...loader, prompt: e.target.value })}
                          value={loader.prompt}
                        ></textarea>
                        <label
                          className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-white transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-white before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-white after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-white peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-white peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:white peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-white peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-white"
                        >
                          Prompt
                        </label>
                      </div>
                    </div>
                    <div className={`mb-4 ${!createMode ? '' : 'hidden'} delete-loader`}>
                      <button className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded" onClick={() => deleteLoader()}>Radera källa</button>
                    </div>
                  </div>
                </div>
                <div className={`mb-4 ${!createMode ? '' : 'hidden'}`}>
                  <div className='mt-8 flex flex-row mb-5'>
                    <div className='mr-10'>
                      <label htmlFor="uploadFolder" className="text-sm text-gray-500">Ladda upp en mapp</label>
                      <Button className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded" onClick={() => { document.getElementById("uploadFolder")?.click() }}>Ladda upp en mapp</Button>
                      <input id="uploadFolder" className="block w-full text-sm border rounded-lg cursor-pointertext-neutral-400 focus:outline-none bg-neutral-700 border-neutral-600 placeholder-neutral-400 hidden" type="file" multiple onInput={handleUpload} {...directory} />
                    </div>
                    <div className="upload-btn-wrapper">
                      <label htmlFor="uploadFiles" className="text-sm text-gray-500">Ladda upp en eller flera filer</label>
                      <Button className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded" onClick={() => { document.getElementById("uploadFiles")?.click() }}>eller en eller flera filer</Button>
                      <input id="uploadFiles" className="block w-full text-sm border rounded-lg cursor-pointertext-neutral-400 focus:outline-none bg-neutral-700 border-neutral-600 placeholder-neutral-400 hidden" type="file" multiple onInput={handleUpload} />
                    </div>
                  </div>

                  <div>
                    {Array.isArray(files) && files.length > 0 && (
                      <div className='flex mt-8 place-content-end'>
                        <button type="button" className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded" onClick={() => deleteAllFiles()}>Radera alla filer</button>
                      </div>
                    )}
                    <label className="text-sm text-gray-500">Filer</label>
                    <div className="relative h-96 overflow-y-auto border border-gray-200 rounded-md shadow-sm p-4 bg-dark">
                      {(isFetchingFiles || isUploading ) ? (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="flex items-center space-x-2 text-sm text-gray-500">
                            <svg
                              className="w-6 h-6 animate-spin text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                              ></path>
                            </svg>
                            <span>Laddar...</span>
                          </div>
                        </div>
                      ) : (
                        <ul className="space-y-2">
                          {Array.isArray(files) && files.length === 0 && (
                            <li className="text-center text-gray-500">Inga filer</li>
                          )}
                          {Array.isArray(files) &&
                            files.map((file: string, index: number) => (
                              <li
                                key={index}
                                className="flex items-center justify-between rounded-lg transition-all"
                              >
                                <div className="flex-1 text-sm text-white">
                                  {file.replace(`data/${customer?.name}/${loader.folder}`, "")}
                                </div>
                                <button
                                  title="Ta bort"
                                  type="button"
                                  className="p-1 rounded-full hover:bg-red-500 hover:text-white transition-all"
                                  onClick={() => handleDeleteFile(file)}
                                >
                                  <svg
                                    className="w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Loaders;
