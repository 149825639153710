import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ChatState {
  id: string;
}

const initialState: ChatState= {
  id: "",
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatId(state: ChatState, action: PayloadAction<string>) {
      state.id = action.payload;
    }
  }

});

export const { setChatId } = chatSlice.actions;
export default chatSlice.reducer;

