import React, { useEffect, useState } from "react";
import { setChatId } from "../../domain/chats/chat-slice";
import { setChats } from "../../domain/chats/chats-slice";
import { Chat, Chats, LoaderType, useFetchChatsQuery, useGetLoaderTypesQuery, useSetLoaderTypeMutation } from "../../infrastructure/api-slice";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import Button from "../Atoms/Buttons/Button";

import { setLoaderType as setAppLoaderType } from "../../domain/loader_types/loader_type-slice";

import Select from "react-tailwindcss-select";
import { Options, SelectValue } from "react-tailwindcss-select/dist/components/type";
import { setIsSearching } from "../../domain/search/search-slice";

function PublicNavigation() {

  const dispatch = useAppDispatch();
  const chatId = useAppSelector(state => state.chat.id)
  const chats = useAppSelector(state => state.chats.chats)
  const isLoggedIn = useAppSelector(state => state.user.isLoggedIn)
  const [page, setPage] = useState(1);
  const page_size = 10;
  const [sessionId, setSessionId] = useState(Date.now());
  const { data = {} as Chats, isFetching } = useFetchChatsQuery({ amount: page_size, page: page, sessionId: sessionId });
  const { data: loaderTypesData, isFetching: isFetchingLoaderTypes } = useGetLoaderTypesQuery();
  const [setLoaderTypes, result] = useSetLoaderTypeMutation();
  const [loaderTypes, setLoaderTypesState] = useState<LoaderType[]>([]);
  const [selectedLoaderType, setSelectedLoaderType] = useState<string>();
  const [loaderType, setLoaderType] = useState<SelectValue>(null);
  const [options, setOptions] = useState<Options>([]);
  const isSearching = useAppSelector(state => state.search.isSearching)

  useEffect(() => {
    if (isFetchingLoaderTypes)
      dispatch(setIsSearching(true))

    if (!isFetchingLoaderTypes && loaderTypesData)
      setLoaderTypesState(loaderTypesData)

    dispatch(setIsSearching(false))

  }, [dispatch, isFetchingLoaderTypes, loaderTypesData]);


  useEffect(() => {
    if (selectedLoaderType) {
      setLoaderTypes(selectedLoaderType)
      dispatch(setIsSearching(true))
    }

  }, [dispatch, selectedLoaderType, setLoaderTypes]);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(setIsSearching(false))
    }
  }, [dispatch, result])

  useEffect(() => {
    if (loaderTypes.length > 0) {
      setOptions(loaderTypes.map((loaderType) => ({ label: loaderType.description, value: loaderType.name })));
      let first = loaderTypes[0];
      dispatch(setAppLoaderType({ name: first.name, description: first.description }));
    }
  }, [dispatch, loaderTypes]);

  useEffect(() => {
    const search = document.getElementById("search");
    if (search)
      search.classList[isSearching ? 'add' : 'remove']("disabled");
  }, [isSearching])

  useEffect(() => {
    const preSelectedLoaderTypes = options.length > 0 ? loaderTypes.filter((loaderType) => loaderType.is_pre_selected === true) : [];
    if (preSelectedLoaderTypes.length > 0) {
      setLoaderType({ label: preSelectedLoaderTypes[0].description, value: preSelectedLoaderTypes[0].name });
    }
  }, [loaderTypes, options])


  useEffect(() => {
    if (!isFetching)
      dispatch(setChats(data.chats))
  }, [data.chats, dispatch, isFetching])


  const setChat = (id: string) => {
    dispatch(setChatId(id))
  }

  const handleChange = (value: any) => {
    if (!value) return;
    setChat("");
    setLoaderType(value);
    setSelectedLoaderType(value.value);
    dispatch(setAppLoaderType({ name: value.value, description: value.label }));
  }

  return (
    <>
      {isLoggedIn && (
        <div>
          <div id="search" className="mb-10">
            <Button className="shadow-none hover:shadow-none" onClick={() => { setChat("") }} text="Ny konversation" />
            <Select
              value={loaderType}
              onChange={handleChange}
              options={options}
              primaryColor={"blue"}
              isMultiple={false}
              searchInputPlaceholder="Sök..."
              isSearchable={false}
              placeholder="Välj källa"
              classNames={{
                menuButton: () => (
                  `flex text-sm px-4 text-gray-500 border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none ${"bg-black hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                  }`
                ),
                menu: "absolute z-10 w-full bg-black shadow-lg border rounded py-1 mt-1.5 text-sm text-white",
                searchBox: "px-4 w-full py-2 pl-8 text-sm bg-black text-white  border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                listItem: () => (
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded hover:bg-neutral-800 ${`text-white bg-black`
                  }`
                ),
                tagItem: () => ("bg-black border text-white rounded-sm flex space-x-1 pl-1"),
                tagItemText: "text-white",
              }}
            />
          </div>

          <div>
            <div className="text-neutral-400 text-sm mb-2">Senaste konversationerna</div>
            <ul>
              {chats?.map((chat: Chat, index) => {
                return <li key={index} className="mb-2">
                  <Button className="shadow-none hover:shadow-none" onClick={() => { setChat(chat.chatId); setLoaderType({ label: chat.loader_type_description, value: chat.loader_type_name }); setSelectedLoaderType(chat.loader_type_name) }} text={chat.title} active={chat.chatId === chatId} />
                </li>
              })}
            </ul>
            <div className="mb-2">
              <div className="flex justify-between">
                <Button onClick={() => { setSessionId(Date.now()); setPage(page - 1) }} text="<" className={page === 1 ? "disabled shadow-none hover:shadow-none" : "shadow-none hover:shadow-none"} />
                <Button onClick={() => { setSessionId(Date.now()); setPage(page + 1) }} text=">" className={page >= (data.chat_count / page_size) ? "disabled text-right shadow-none hover:shadow-none" : "text-right shadow-none hover:shadow-none"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublicNavigation;
